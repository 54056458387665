import React from "react";
import { Link } from "gatsby";

import logoWhole from "../assets/logo-whole.png";
import useEscapeHandler from "../hooks/escape-handler";
import useKeyHandler from "../hooks/use-key-handler";
import Address from "./address";
import Hours from "./hours";
import Phone from "./phone";

export default function Sidebar(props) {
  const { isOpen, toggle } = props;
  const cls =
    "sv-sidebar px3 md-flex flex-column justify-content items-center justify-center overflow-scroll" +
    (isOpen ? "" : " hidden");
  useEscapeHandler(isOpen, toggle);
  const closeKeyDownHandler = useKeyHandler(toggle, ["Enter", " "]);

  function toggleCloseKeydown(e) {
    if (closeKeyDownHandler(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <div className={cls}>
      <div className="flex justify-start items-center sv-sidebar-header">
        <div
          aria-label="close sidebar"
          className="sv-navbar-trigger items-start"
          onClick={toggle}
          onKeyDown={toggleCloseKeydown}
          role="button"
          tabIndex="0"
        >
          ✕
        </div>
      </div>
      <nav className="sv-sidebar-nav sv-nav">
        <ul className="list-reset m0 p0 sv-label">
          <li>
            <Link
              to="/"
              className="sv-sidebar-logo text-decoration-none block min-height-hide"
              onClick={toggle}
            >
              <img
                src={logoWhole}
                className="sv-sidebar-nav-image inline-block mb1"
                alt="Simply Vision Optometry"
              />
            </Link>
          </li>
          <li className="sv-nav-item ">
            <a
              className="sv-nav-link"
              href="https://app.eyecloudpro.com/site/!login?sid=5C241BA24405C1755C8C3373345C7FF0704A0191FDE0B69AE91EC7E31B6D02D9"
              target="_blank"
            >
              Patient Portal
            </a>
          </li>
          <li className="sv-nav-item ">
            <Link className="sv-nav-link" to="/about" onClick={toggle}>
              About Us
            </Link>
          </li>
          {/* <li className="sv-nav-item ">
            <Link className="sv-nav-link" to="/reviews" onClick={toggle}>
              Reviews
            </Link>
          </li> */}
          <li className="sv-nav-item ">
            <Link className="sv-nav-link" to="/insurance" onClick={toggle}>
              Insurance
            </Link>
          </li>
          <li className="sv-nav-item ">
            <Link className="sv-nav-link" to="/brands" onClick={toggle}>
              Brands
            </Link>
          </li>
        </ul>
      </nav>

      <h3 className="h7 block pt3">Contact Us</h3>
      <div className="pt2">
        <div className="md-mb1">
          <Phone />
        </div>
        <Address />
      </div>

      <h3 className="h7 block pt3">Office Hours</h3>
      <div className="pt2">
        <Hours />
      </div>
    </div>
  );
}
